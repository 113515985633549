import React from 'react'
import {useEffect, useState} from 'react';
import './Home.css';
import { useLocale } from '../../LocaleContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import event from '../../img/event.webp';
import review1 from '../../img/review1.webp';
import review2 from '../../img/review2.webp';
import review3 from '../../img/review3.webp';
export default function Home() {





  const { locale } = useLocale();
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetching data from the API
    axios.get('https://dashboard.roushda.com/api/programs' , {headers:{'Accept-Language': locale}})
      .then(response => {
        setPrograms(response.data.data);
        setLoading(false); // Stop loading when data is fetched
      })
      .catch(error => {
        console.log("There was an error fetching the programs!", error);
        setLoading(false); // Stop loading on error
      });
  }, [locale]);

  if (loading) {
    // Loading screen while programs data is being fetched
    return (
      <div className="loading-screen d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }


  return <>
  
  
  {locale === 'ar' ? <div className='rtl'>
    <section className='vh-100 mb-5' id='home'></section>
<section id='aboutUs' >
    <h2 className='m-3 mainColor heading text-center'>من نحن</h2>
    <h3 className='m-5 mainColor text-center'>رشـــــدا.. أول حاضنة تربوية للإعداد التربوي للأطفال والمراهقين في مصر</h3>
    <div className='container'>
      <div className="row">
        <div className='col-md-6 p-lg-0 largeScreen'>
          <div>
          <p className='size one fs-5'>تأسست رشداً كمؤسسة معنية بإعداد المراهقين لإجتياز مرحلة المراهقة و الوصول لمرحلة الرشد و المسؤلية والأتزان النفسي لإعداد المراهقين بطريقة علمية وعملية وسحبه من العالم الإفتراضي والسوشيال ميديا إلى العالم الحقيقي</p>
          <p className='size two fs-5 mb-0'>رؤية رشداً في تخريج أطفال ويافعين على درجة من المسئولية والرُشد والاتزان النفسي بتعتمد ديماً على توظيف طاقة أولادنا و شغفهم بأمور بيحبوها في إعدادهم وتربيتهم وبناء شخصيتهم، دا بيساعدنا على زيادة وعيهم وبالتالي تعديل أولوياتهم و من ثم تعديل سلوكياتهم والوصول بهم للمسئولية والرُشد</p>
          </div>
        </div>
        <div className='col-md-6 largeScreen p-lg-0 d-flex align-items-center'>
          <div >
            <img src={event} className='w-100 divHover' alt="event" />
          </div>
        </div>
      </div>
    </div>
    
  </section>

  <section id='mission-values' className='my-5'>
    <div className='container'>
      <div className='row'>
        <div className="col-md-12">
          <div>
            <h2 className='text-warning my-5 text-center'>&quot; مهمتنا &quot;</h2>
            <p className='text-white fs-5 py-3 px-4 mission'>إعداد الاطفال والمراهقين تربويا وإخلاقيا وسلوكيآ للوصول بهم الى مرحلة الرشد والمسؤلية عن طريق برامج تفاعلية مكونة من 6 مسارات تربوية  تعتمد على اسلوب المعايشة في التعلم والتأهيل النفسي من خلال اخصائيين نفسيين وتربوين</p>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            <h2 className='text-warning my-5 text-center'>&quot; قيمنا &quot;</h2>
              <ul className='mb-4 text-white py-3 px-5 mission'>
                <li className='m-2 fs-5'>التفاني وايماننا بدورنا في المجتمع من خلال بناء جيل قادر على مواجهة تحديات هذا العصر.</li>
                <li className='m-2 fs-5'>التقدير واالاحترام والدعم المستمر أمر ضروري ومتبادل بين كل أفراد عائلتنا الكبيرة.</li>
                <li className='m-2 fs-5'>النجاح الحقيقي هو القدرة على الاستمتاع بما أنجزناه في الحاضر، بينما ننظر نحو المستقبل ونعمل له.</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div className='container'>
    <div className='row justify-content-center align-items-center'>
      <div className='col-md-6 '>
        <h4 className='mainColor text-center'>هل تريد المزيد من التفاصيل؟</h4>
      </div>
      <div className='col-md-6 mTop text-center divHover'> 
      <button className='btn text-white btn-lg background'><Link to='https://wa.me/+201015307670' target='_blank' className='text-decoration-none text-white'>تواصل معنا الان</Link></button>
    </div> 
    </div>
    
  </div>






  <section  id='services' className='my-5 text-white background'>
    <h2 className='m-3 pt-5 yellow text-center'>خدماتنا</h2>
    <div className="container">
      <div className="programs">
        {programs.map((program, index) => (
          <div className="mainProgram " key={index}>
            <div className="card-body">
              <h2 className='yellow my-3'>{program.name}:</h2>
              <p>{program.description}</p>
              
                  
        

                

              {/* Sub-programs */}
              {program.sub_programs.length > 0 && (
                <>
                <div className='container'>
                  
                  <div className='row'>
                  {program.sub_programs.map((subProgram, subIndex) => (
                    <div className="col-md-4 divHover my-3" key={subIndex}>
                      
                    <div className='rounded-3 border border-1 border-warning'>
            
                      <h4 className='service-h rounded-top p-2 text-center'>{subProgram.name}</h4>
                      <div className='p-3'>
                        <p className='text-center p-2 text-white'>{subProgram.description}</p>
                        
                        <ul>
                          <li className=''><strong>عدد الحصص:</strong> {subProgram.sessions_count}</li>
                          <li className=''><strong>نوع الطفل:</strong> {subProgram.type_of_child}</li>
                          <li className=''><strong>عمر الطفل:</strong> {subProgram.min_age} - {subProgram.max_age}</li>
                          <li><strong>السعر:</strong> {subProgram.price} جنيها</li>
                        </ul>
                      </div>
                    </div>
                    </div>
                  ))}
                  
                  </div>
                </div>
                  
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section> 




  

  <section id='reviews' className='reviews divOfCarousel'>
    <h2 className='mainColor text-center'>التقييمات</h2>
    <div id="carouselExampleRide" className="carousel w-100 slide" data-bs-ride="true">
      <div className="carousel-inner ">
        <div className="carousel-item my-5 divOfCaruosel w-100 active">
            <img src={review1} id='imgOfCarousel' className="d-block carouselForResponsive m-auto w-25" alt="review1"/>
        </div>
        <div className="carousel-item my-5 divOfCaruosel w-100">
            <img src={review2} id='imgOfCarousel' className="d-block carouselForResponsive m-auto w-25" alt="review2"/>
        </div>
        <div className="carousel-item my-5 divOfCaruosel w-100">
            <img src={review3} id='imgOfCarousel' className="d-block carouselForResponsive m-auto w-25" alt="review3"/>
        </div>
  </div>
  <button className="carousel-control-prev carouselControlPrev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
    <span className="carousel-control-prev-icon d-flex justify-content-center p-4 align-items-center" aria-hidden="true"><i className="fa-solid fa-3x fa-chevron-left"></i></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next carouselControlNext" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
    <span className="carousel-control-next-icon d-flex justify-content-center p-4 align-items-center" aria-hidden="true"><i className="fa-solid fa-3x fa-chevron-right"></i></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    
  </section>

































  </div> : <div className='ltr'>
  <section className='vh-100 mb-5' id='home'></section>
<section id='aboutUs' >
    <h2 className='m-3 mainColor heading text-center'>Who we are</h2>
    <h3 className='m-5 mainColor text-center'>Roshda... The first educational incubator for educational preparation for children and adolescents in Egypt</h3>
    <div className='container'>
      <div className="row">
        <div className='col-md-6 p-lg-0 largeScreen'>
          <div>
          <p className='size one fs-5'>Roshda was established as an institution concerned with preparing adolescents to pass the stage of adolescence and reach the stage of adulthood, responsibility and psychological balance to prepare adolescents in a scientific and practical way and to pull them from the virtual world and social media to the real world.</p>
          <p className='size two fs-5 mb-0'>Roshda’s vision of graduating children and adolescents with a degree of responsibility, maturity and psychological balance always depends on employing our children’s energy and passion for things they love in preparing, raising and building their character. This helps us increase their awareness and consequently adjust their priorities and then adjust their behaviors and bring them to responsibility and maturity.</p>
          </div>
        </div>
        <div className='col-md-6 d-flex p-lg-0 largeScreen align-items-center'>
          <div >
            <img src={event} className='w-100 divHover' alt="event" />
          </div>
        </div>
      </div>
    </div>
    
  </section>

  <section id='mission-values' className='my-5'>
    <div className='container'>
      <div className='row'>
        <div className="col-md-12">
          <div>
            <h2 className='text-warning my-5 text-center'>&quot; Our mission &quot;</h2>
            <p className='text-white fs-5 py-3 px-4 mission'>Preparing children and adolescents educationally, morally and behaviorally to reach the stage of adulthood and responsibility through interactive programs consisting of 6 educational tracks that depend on the method of coexistence in learning and psychological rehabilitation through psychological and educational specialists.</p>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            <h2 className='text-warning my-5 text-center'>&quot; Our values &quot;</h2>
              <ul className='mb-4 text-white py-3 px-5 mission'>
                <li className='m-2 fs-5'>Dedication and belief in our role in society by building a generation capable of facing the challenges of this era.</li>
                <li className='m-2 fs-5'>Appreciation, respect and ongoing support are essential and mutual among all members of our extended family.</li>
                <li className='m-2 fs-5'>True success is the ability to enjoy what we have accomplished in the present, while looking toward the future and working for it.</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div className='container'>
    <div className='row justify-content-center align-items-center'>
      <div className='col-md-6 '>
        <h4 className='mainColor mTop text-center'>Do you need more details?</h4>
      </div>
      <div className='col-md-6 mTop text-center divHover'> 
      <button className='btn text-white btn-lg background'><Link to='https://wa.me/+201015307670' target='_blank' className='text-decoration-none text-white'>Contact us</Link></button>
    </div> 
    </div>
    
  </div>






  <section  id='services' className='my-5 text-white background'>
    <h2 className='m-3 pt-5 yellow text-center'>Our services</h2>
    <div className="container">
      <div className="programs">
        {programs.map((program, index) => (
          <div className="mainProgram " key={index}>
            <div className="card-body">
              <h2 className='yellow my-3'>{program.name}:</h2>
              <p>{program.description}</p>
              
                  
                  

                

              {/* Sub-programs */}
              {program.sub_programs.length > 0 && (
                <>
                <div className='container'>
                  
                  <div className='row'>
                  {program.sub_programs.map((subProgram, subIndex) => (
                    <div className="col-md-4 divHover my-3" key={subIndex}>
                      
                    <div className='rounded-3 border border-1 border-warning'>
            
                      <h4 className='service-h rounded-top p-2 text-center'>{subProgram.name}</h4>
                      <div className='p-3'>
                        <p className='text-center p-2 text-white'>{subProgram.description}</p>
                        
                        <ul>
                          <li className=''><strong>Sessions:</strong> {subProgram.sessions_count}</li>
                          <li className=''><strong>Type of child:</strong> {subProgram.type_of_child}</li>
                          <li className=''><strong>Age of child:</strong> {subProgram.min_age} - {subProgram.max_age}</li>
                          <li><strong>Price:</strong> {subProgram.price} L.E.</li>
                        </ul>
                      </div>
                    </div>
                    </div>
                  ))}
                  
                  </div>
                </div>
                  
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section> 




  

  <section id='reviews' className='reviews divOfCarousel'>
    <h2 className='mainColor text-center'>Reviews</h2>
    <div id="carouselExampleRide" className="carousel w-100 slide" data-bs-ride="true">
      <div className="carousel-inner ">
        <div className="carousel-item my-5 divOfCaruosel w-100 active">
            <img src={review1} id='imgOfCarousel' className="d-block carouselForResponsive m-auto w-25" alt="review1"/>
        </div>
        <div className="carousel-item my-5 divOfCaruosel w-100">
            <img src={review2} id='imgOfCarousel' className="d-block carouselForResponsive m-auto w-25" alt="review2"/>
        </div>
        <div className="carousel-item my-5 divOfCaruosel w-100">
            <img src={review3} id='imgOfCarousel' className="d-block carouselForResponsive m-auto w-25" alt="review3"/>
        </div>
  </div>
  <button className="carousel-control-prev carouselControlPrev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
    <span className="carousel-control-prev-icon d-flex justify-content-center p-4 align-items-center" aria-hidden="true"><i className="fa-solid fa-3x fa-chevron-left"></i></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next carouselControlNext" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
    <span className="carousel-control-next-icon d-flex justify-content-center p-4 align-items-center" aria-hidden="true"><i className="fa-solid fa-3x fa-chevron-right"></i></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    
  </section>
  </div> }

    
  </>
}