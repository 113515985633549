import React from 'react';
import { useLocale } from '../../LocaleContext'; // adjust the path as necessary

const Navbar = () => {
  const { locale, setLocale } = useLocale();

  const handleLocaleChange = (event) => {
    const selectedLocale = event.target.value;
    setLocale(selectedLocale);

    // Optionally, you can save the locale in localStorage
    localStorage.setItem('locale', selectedLocale);
  };

  return (
    <select
      className="dropdown-toggle mx-2 btn-outline-success btn p-2"
      value={locale}
      onChange={handleLocaleChange}
    >
      <option value="ar">العربية</option>
      <option value="en">English</option>
    </select>
  );
};

export default Navbar;
