import React, { createContext, useContext, useState, useEffect } from 'react';

const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  // Check localStorage for existing locale or default to 'en'
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en');

  useEffect(() => {
    // Save the current locale to localStorage whenever it changes
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
