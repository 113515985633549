import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return <footer className=" yellow bg-darkGreen text-center py-4">
  <div className="container yellow">
      <div className="row">
          <div className="col-md-12">
              <Link to="https://www.facebook.com/roshdaegypt1" className="text-white mx-2" target="_blank">
                  <i className="fab hov fa-facebook fa-2x"></i>
              </Link>
              <Link to="mailto:info@roushda.com" className="text-white mx-2" target="_blank">
              <i className="fa-solid fa-envelope hov fa-2x"></i>
              </Link>
              <Link to="https://www.instagram.com/roshda_egypt/" className="text-white mx-2" target="_blank">
                    <i className="fab hov fa-instagram fa-2x"></i>
              </Link>
              <Link to="https://www.linkedin.com/company/roshda/" className="text-white mx-2" target="_blank">
                    <i className="fab hov fa-linkedin fa-2x"></i>
              </Link>
          </div>
      </div>
      <div className="row mt-3 direction">
          <div className="col-md-12">
              <p>&copy; 2024 Roshda. All rights reserved.</p>
          </div>
      </div>
  </div>
</footer>
}
