import axios from 'axios';
import { useFormik } from 'formik';
import styles from "./auth.module.css";
import React, { useState } from 'react';
import { useLocale } from '../../LocaleContext';
import { Vortex } from 'react-loader-spinner';

export default function Register() {
  
  const { locale } = useLocale();  
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Initial user data
  const initialUserData = {
    name: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
  };


  // Register new user function
  async function registerNewUser(values) {
    setIsLoading(true);
    setErrorMsg(null); // Reset error message

    try {
      const requestData = { ...values };
      const { data } = await axios.post(
        "https://dashboard.roushda.com/api/register",
        requestData, 
        { headers: { 'Accept-Language': locale } }
      );

      if (data.message === "Successfully Registered" || 'تم التسجيل بنجاح') {
        setSuccessMsg(data.message);
        setTimeout(() => {
          window.location.href = 'https://dashboard.roushda.com/parent/login';
        }, 1500);
      }
    } catch (e) {
      setErrorMsg(e.response.data.errors);
      
      
      
    }

    setIsLoading(false);
  }

  // Formik object for handling form state and validation
  const formikObj = useFormik({
    initialValues: initialUserData,
    onSubmit: registerNewUser,
    // validate: validateForm,
  });

  // Validation logic
  // function validateForm(values) {
  //   const errors = {};
  //   if (values.name.length < 4 || values.name.length > 20) {
  //     errors.name = locale === 'ar' ? "يجب أن يكون الاسم مكونًا من 4 أحرف على الأقل" : "Name must be at least 4 characters";
  //   }

  //   if (!values.email.includes("@") || !values.email.includes(".")) {
  //     errors.email = locale === 'ar' ? "أدخل بريدًا إلكترونيًا صالحًا" : "Enter a valid Email";
  //   }

  //   if (!values.phone.match(/^(02)?01[0125][0-9]{8}$/)) {
  //     errors.phone = locale === 'ar' ? "أدخل رقم هاتف صالح" : "Enter a valid phone number";
  //   }

  //   if (values.password.length < 8) {
  //     errors.password = locale === 'ar' ? "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل" : "Password must be at least 8 characters";
  //   }

  //   if (values.password_confirmation !== values.password) {
  //     errors.password_confirmation = locale === 'ar' ? "كلمات المرور غير متطابقة" : "Passwords don't match";
  //   }

  //   return errors;
  // }

  // Helper for rendering error messages
  


  

  return <>
  {locale === 'ar' ? <div className={`${styles.parent} rtl py-3`}>
  {/* {errorMsg ? <div className="alert w-50 text-center m-auto alert-danger">هذا الحساب مسجل بالفعل</div> : ""} */}
  {successMsg ? <div className="alert w-50 text-center m-auto alert-success">تم التسجيل بنجاح</div> : ""}
  <div className={`${styles.container}`}>
    <div className={`${styles.card} ${styles.widthOfCard} bg-white rounded-5 border`}>

  <div className='row'>
  <div className={`${styles.arCardRight} col-md-6`}>
            <h2>مرحبا!</h2>
            <p className='text-center'>هل تريد أن تكون عضوا في عائلة رشدا؟</p>
  </div>

  <div className={`${styles.arCardLeft} py-4 col-md-6`}>
  <h1 className='text-center mt-lg-2'>انشاء حساب</h1>
  <form className='pb-5 rtl' onSubmit={formikObj.handleSubmit}>
    
    <input
      onChange={formikObj.handleChange}
      onBlur={formikObj.handleBlur}
      id="name"
      value={formikObj.values.name}
      type="text"
      placeholder='الاسم'
    />
    {errorMsg ? (
      <div className={`${styles.red} w-100 m-0`}>{errorMsg.name}</div>
    ) : (
      <div className="text-danger w-100 m-0"></div>
    )}

    <input
      className='rtl'
      onChange={formikObj.handleChange}
      onBlur={formikObj.handleBlur}
      id="phone"
      value={formikObj.values.phone}
      type="tel"
      placeholder="رقم الهاتف"
    />
    {errorMsg ? (
      <div className={`${styles.red} w-100 m-0`}>{errorMsg.phone}</div>
    ) : (
      <div className={`${styles.red} w-100 m-0`}></div>
    )}

    <input
      onChange={formikObj.handleChange}
      onBlur={formikObj.handleBlur}
      id="email"
      value={formikObj.values.email}
      type="email"
      placeholder="البريد الالكتروني"
    />
    {errorMsg ? (
      <div className={`${styles.red} w-100 m-0`}>{errorMsg.email}</div>
    ) : (
      <div className={`${styles.red} w-100 m-0`}></div>
    )}

    <input
      onChange={formikObj.handleChange}
      onBlur={formikObj.handleBlur}
      id="password"
      value={formikObj.values.password}
      type="password"
      placeholder="الرقم السري"
    />
    {errorMsg ? (
      <div className={`${styles.red} w-100 m-0`}>{errorMsg.password}</div>
    ) : (
      <div className={`${styles.red} w-100 m-0`}></div>
    )}

    <input
      onChange={formikObj.handleChange}
      onBlur={formikObj.handleBlur}
      id="password_confirmation"
      value={formikObj.values.password_confirmation}
      type="password"
      placeholder="تأكيد الرقم السري"
    />
    

    
    <button type="submit" className={`${styles.butn} w-100`}>
    { isLoading ? <Vortex
              visible={true}
              height=""
              width="30"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={['#ff009d', '#ff009d', '#FBCB1D', '#FBCB1D', '#029cca', '#029cca']}
            />  : 'تسجيل' }
      </button>
  </form>
  </div>

  
    
    
    {/* <!-- Right side of the card --> */}
    
    
    </div>

    </div>
        
    
    
</div>
</div> : <div className={`${styles.parent} py-3`}>
    {/* {errorMsg ? <div className="alert w-50 text-center m-auto alert-danger">Email already exists</div> : ""} */}
    {successMsg ? <div className="alert w-50 text-center m-auto alert-success">Successfully registered</div> : ""}
    <div className={`${styles.container}`}>
      <div className={`${styles.card} ${styles.widthOfCard} bg-white rounded-5 border`}>

        <div className='row'>
        <div className={`${styles.enCardLeft} pe-lg-1 paddinge-0 col-md-6`}>
                  <h2>Welcome</h2>
                  <p className='text-center'>Do you want to be a member of the Roshda family?</p>
          </div>

        <div className={`${styles.enCardRight}  col-md-6`}>
        <h1 className='text-center'>Create an account!</h1>
        <form className='pb-5' onSubmit={formikObj.handleSubmit}>
          
          <input
            onChange={formikObj.handleChange}
            onBlur={formikObj.handleBlur}
            id="name"
            value={formikObj.values.name}
            type="text"
            placeholder='Name'
          />
          {errorMsg ? (
          <div className={`${styles.red} w-100 m-0`}>{errorMsg.name}</div>
          ) : (
          <div className={`${styles.red} w-100 m-0`}></div>
          )}

          <input
            onChange={formikObj.handleChange}
            onBlur={formikObj.handleBlur}
            id="phone"
            value={formikObj.values.phone}
            type="tel"
            placeholder="Phone"
          />
          {errorMsg ? (
          <div className={`${styles.red} w-100 m-0`}>{errorMsg.phone}</div>
          ) : (
          <div className={`${styles.red} w-100 m-0`}></div>
          )}

          <input
            onChange={formikObj.handleChange}
            onBlur={formikObj.handleBlur}
            id="email"
            value={formikObj.values.email}
            type="email"
            placeholder="Email"
          />
          {errorMsg ? (
          <div className={`${styles.red} w-100 m-0`}>{errorMsg.email}</div>
          ) : (
          <div className={`${styles.red} w-100 m-0`}></div>
          )}

          <input
            onChange={formikObj.handleChange}
            onBlur={formikObj.handleBlur}
            id="password"
            value={formikObj.values.password}
            type="password"
            placeholder="Password"
          />
          {errorMsg ? (
          <div className={`${styles.red} w-100 m-0`}>{errorMsg.password}</div>
          ) : (
          <div className={`${styles.red} w-100 m-0`}></div>
          )}

          <input
            onChange={formikObj.handleChange}
            onBlur={formikObj.handleBlur}
            id="password_confirmation"
            value={formikObj.values.password_confirmation}
            type="password"
            placeholder="Re-password"
          />
          

          
          <button type="submit" className={`${styles.butn} w-100`}>
            { isLoading ? <Vortex
              visible={true}
              height=""
              width="30"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={['#ff009d', '#ff009d', '#FBCB1D', '#FBCB1D', '#029cca', '#029cca']}
            />  : 'Sign up' }
            
            
            
            
            
            </button>
        </form>
        </div>
          
          
          {/* <!-- Right side of the card --> */}
          
          
          </div>

          </div>
              
          
          
      </div>
      </div>}
</>
}
