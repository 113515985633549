import React, { useState } from 'react';
import './Navbar.css';
import Logo from '../../img/res-Photoroom.webp';
import LocalSwitcher from '../LocalSwitcher/LocalSwitcher';
import { HashLink as Link } from 'react-router-hash-link'; // Import HashLink
import { useLocale } from '../../LocaleContext';




export default function Navbar() {
  const handleRedirect = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      window.open(selectedValue , "_blank") ;
    }
  };
  
  const { locale } = useLocale();
  const [isToggled, setIsToggled] = useState(false); // Track if toggler is opened

  const handleToggle = () => {
    setIsToggled(!isToggled); // Toggle the state
  };

  return (
    <>
      {locale === 'ar' ? (
        <nav className="navbar rtl p-0 w-100 navbar-expand-lg bg-white">
          <div className="container-fluid p-0">
            <Link className="navbar-brand linkSize" smooth to={`/home`}>
              <img src={Logo} className="w-100" alt="logo" />
            </Link>
            <button
              className="navbar-toggler outline ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={isToggled} // Set true/false for accessibility
              aria-label="Toggle navigation"
              onClick={handleToggle} // Handle toggle click
            >
              {isToggled ? (
                <i className="fa-solid mainColor fs-2 fa-times"></i> // X icon when toggled
              ) : (
                <i className="fa-solid mainColor fs-2 fa-bars"></i> // Bars icon when not toggled
              )}
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav p-0 me-auto mb-2 mb-lg-0">
                <li className="nav-item customNav-link">
                  <Link className="nav-link active" aria-current="page" smooth to="/home">
                    الصفحة الرئيسية
                  </Link>
                </li>
                <li className="nav-item customNav-link">
                  <Link className="nav-link" smooth to="/#aboutUs">
                    من نحن
                  </Link>
                </li>
                <li className="nav-item customNav-link">
                  <Link className="nav-link" smooth to="/#services">
                    خدماتنا
                  </Link>
                </li>
                <li className="nav-item customNav-link">
                  <Link className="nav-link" smooth to="/#reviews">
                    التقييمات
                  </Link>
                </li>
              </ul>
              <LocalSwitcher />
              <ul className="navbar-nav mForRes mx-2 p-0 me-auto mb-2 mb-lg-0">
              <li>
              <select
                  onChange={handleRedirect}
                  className="dropdown-toggle p-2 toggler btn-outline-success btn nav-item customNav-link m-1 me-2 border-1 border"
                >
                  <option value="">تسجيل دخول</option> {/* Placeholder option */}
                  <option value="https://dashboard.roushda.com/admin/login">أدمن</option>
                  <option value="https://dashboard.roushda.com/manager/login">مدير</option>
                  <option value="https://dashboard.roushda.com/accountant/login">محاسب</option>
                  <option value="https://dashboard.roushda.com/teacher/login">مدرس</option>
                  <option value="https://dashboard.roushda.com/parent/login">ولي أمر</option>
                  <option value="https://dashboard.roushda.com/child/login">طفل</option>
              </select>
                </li>
                <li className="nav-item customNav-link m-1 me-2 border-1 rounded-2 border">
                  <Link className="nav-link" to="/register">
                    <i className="fa-solid fa-user-plus m-1"></i>حساب جديد
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="navbar p-0 w-100 ltr navbar-expand-lg bg-white">
          <div className="container-fluid p-0">
            <Link className="navbar-brand linkSize" smooth to={`/home`}>
              <img src={Logo} className="w-100" alt="logo" />
            </Link>
            <button
              className="navbar-toggler outline me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={isToggled} // Set true/false for accessibility
              aria-label="Toggle navigation"
              onClick={handleToggle} // Handle toggle click
            >
              {isToggled ? (
                <i className="fa-solid mainColor fs-2 fa-times"></i> // X icon when toggled
              ) : (
                <i className="fa-solid mainColor fs-2 fa-bars"></i> // Bars icon when not toggled
              )}
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav p-0 ms-auto mb-2 mb-lg-0">
                <li className="nav-item customNav-link">
                  <Link className="nav-link active" aria-current="page" smooth to="/home">
                    Home
                  </Link>
                </li>
                <li className="nav-item customNav-link">
                  <Link className="nav-link" smooth to="/#aboutUs">
                    About
                  </Link>
                </li>
                <li className="nav-item customNav-link">
                  <Link className="nav-link" smooth to="/#services">
                    Services
                  </Link>
                </li>
                <li className="nav-item customNav-link">
                  <Link className="nav-link" smooth to="/#reviews">
                    Reviews
                  </Link>
                </li>
              </ul>
              <LocalSwitcher />
              <ul className="navbar-nav mForRes mx-2 d-flex justify-content-center align-items-center mForRes p-0 ms-auto mb-2">
              <li>
              <select
                  onChange={handleRedirect}
                  className="dropdown-toggle p-2 btn-outline-success btn nav-item customNav-link m-1 me-2 border-1 border"
                >
                  <option value="">Login</option> 
                  <option value="https://dashboard.roushda.com/admin/login">Admin</option>
                  <option value="https://dashboard.roushda.com/manager/login">Manager</option>
                  <option value="https://dashboard.roushda.com/accountant/login">Accountant</option>
                  <option value="https://dashboard.roushda.com/teacher/login">Teacher</option>
                  <option value="https://dashboard.roushda.com/parent/login">Parent</option>
                  <option value="https://dashboard.roushda.com/child/login">Child</option>
              </select>
                </li>
                <li className="nav-item customNav-link m-1 me-2 border-1 rounded-2 border">
                  <Link className="nav-link" smooth to="/register">
                    <i className="fa-solid fa-user-plus m-1"></i>New account
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
