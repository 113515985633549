import React from 'react'
import { createHashRouter, RouterProvider } from 'react-router-dom';
import Register from './Components/Register/Register';
import Home from './Components/Home/Home';
import Layout from './Components/Layout/Layout';






const router =  createHashRouter([{path:"/", element:<Layout/>, children:[
  {index: true , element:<Home/>},
  {path:"/home", element:<Home/>},
  {path:"/register", element:<Register/>},
  {path:"*",element:<div className='vh-100 d-flex justify-content-center align-items-center fs-1 text-white'>Not Found</div>}
]}]);
export default function App() {
  
  
  
  
  
  document.addEventListener('click', function(event) {
    var navbarToggler = document.querySelector('.navbar-toggler');
    var navbarCollapse = document.querySelector('.navbar-collapse');
    document.addEventListener('click', function(event) {
      var targetElement = event.target;
    
      if (!targetElement.closest('.navbar') && document.querySelector('.navbar-collapse.show')) {
        document.querySelector('.navbar-collapse').classList.remove('show');
      }
    });
  
    if (!navbarCollapse.contains(event.target) && !navbarToggler.contains(event.target)) {
      // Clicked outside the navbar collapse and the toggler button
      if (navbarCollapse.classList.contains('show')) {
        // Check if the navbar is currently expanded (collapse show class)
        navbarToggler.click(); // Close the navbar by triggering a click on the toggler button
      }
    }
  });
  
  
  
  
  
  return <>
  
  <RouterProvider  router={router}  />
  
  </>
}
